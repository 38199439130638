.logo {
  margin: auto;
  padding-top: 20px;
  img {
    height: 50px;
  }
}

.footerBtn {
  font-size: 3rem;
  // animation: blink 1s step-start 0s infinite;
  // -webkit-animation: blink 1s step-start 0s infinite;
  cursor: pointer;
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}
.topWrapHide {
  opacity: 0.2;
}
@media screen and (max-width: 768px) {
}
