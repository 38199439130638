@charset "utf-8";

@font-face {
  font-family: futuralight;
  src: url("/fonts/futuralight.eot");
  src: url("/fonts/futuralight.ttf"), url("/fonts/futuralight.eot?#iefix"), url("/fonts/futuralight.woff") format("woff"),
    url("/fonts/futuralight.svg") format("svg");
}

@font-face {
  font-family: futura;
  src: url("/fonts/futura.eot");
  src: url("/fonts/futura.ttf"), url("/fonts/futura.eot?#iefix"), url("/fonts/futura.woff") format("woff"), url("/fonts/futura.svg") format("svg");
}
@font-face {
  font-family: futuraBook;
  src: url("/fonts/futuraBook.eot");
  src: url("/fonts/futuraBook.ttf"), url("/fonts/futuraBook.eot?#iefix"), url("/fonts/futuraBook.woff") format("woff"),
    url("/fonts/futuraBook.svg") format("svg");
}
@font-face {
  font-family: futuraBold;
  src: url("/fonts/futuraBold.eot");
  src: url("/fonts/futuraBold.ttf"), url("/fonts/futuraBold.eot?#iefix"), url("/fonts/futuraBold.woff") format("woff"),
    url("/fonts/futuraBold.svg") format("svg");
}

html,
body {
  width: 100%;
  height: 100vh;
  font: normal 13px/20px futura, arial;
  letter-spacing: 1.5px;
}

body {
  color: #494949;
}
a,
a:link,
a:visited,
a:hover,
a:active {
  color: white;
}

a:active {
  color: white;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
