.ant-drawer-content-wrapper {
  width: 30% !important;
  .ant-drawer-content {
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    word-break: keep-all !important;
    .ant-drawer-body {
      padding: 20px;
    }
  }
  .noticeWrap {
    height: 100%;
    font-weight: 100;
    padding-right: 10px;
    .profileWrap {
      padding-bottom: 150px;
    }
    .noticeHeader {
      padding: 10px 0;
      margin-bottom: 25px;
      font-size: 1.2rem;
      span {
        cursor: pointer;
      }
      .noticeSectionSel {
        color: rgb(252, 186, 3);
        font-weight: bold;
      }
    }
    .noticeBody {
      .noticeEng {
        margin-top: 50px;
        font-size: 1.1rem;
      }
      .name {
        .nameTitle {
          width: 100%;
          text-align: center;
          font-size: 2rem;
          margin-bottom: 0;
        }
        .namePosition {
          width: 100%;
          text-align: center;
          font-size: 1rem;
        }
        img {
          display: block;
          margin: auto;
          width: 30%;
        }
      }
      .pfTitle {
        // font-weight: bold;
        margin-top: 25px;
        color: rgb(167, 167, 167);
      }
      .subCon {
        font-size: 0.9rem;
        color: rgba(255, 255, 255, 0.8);
        padding-left: 20px;
      }
    }
  }
}
.CustomScrollsNotice {
  div:nth-child(3) {
    right: -2.5px !important;
  }
}

.projectImgWrap {
  border: 1px solid rgb(182, 182, 182);
  background-color: rgba(5, 130, 233, 0.2);
  img {
    margin: auto;
    width: 100%;
  }
}
.linkicon {
  color: yellow;
  margin: 0 10px;
}
@media screen and (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 80% !important;
  }
  // .ant-drawer-content {
  //   background-color: rgba(255, 255, 255, 0.2) !important;
  // }
}
