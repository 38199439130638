$yellow: rgb(255, 202, 58);

body {
  background: #32bce6 !important;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
}
#root {
  height: 100%;
  .contextWrap {
    position: absolute;
    width: 100%;
    text-align: center;
    color: white;
    top: 0;
    left: 0;
    z-index: 2;
    padding-top: 20px;
    height: 100vh;
    .companyName1 {
      padding-top: 20px;
      font-size: 3rem;
      letter-spacing: 8.5px;
      font-weight: bolder;
      margin-bottom: 0;
    }
    .companyName2 {
      font-size: 1.5rem;
    }
  }

  .contentRichWrapHide {
    opacity: 0.2;
  }
  canvas {
    height: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .upCanvas {
    // background: black;
    opacity: 0.3;
  }
  .contentRichWrap {
    color: rgb(255, 255, 255);
    width: 200px;
    text-align: right;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    padding: 5px;
    z-index: 10;
    p:first-child {
      font-weight: bold;
      border-bottom: 2px solid rgb(255, 255, 255);
    }
    .contentName {
      opacity: 1;
      p {
        margin-bottom: 0.5rem;
      }
    }
    .unSel {
      display: none;
    }
    .Sel {
      opacity: 0.2;
      font-size: 0.5rem;
      p:first-child {
        font-weight: bold;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
    }
  }

  .footer {
    position: absolute;
    top: 0px;
    padding-bottom: 20px;
    width: 100%;
    z-index: 10;
    text-align: center;
    font-size: 2.5rem;
    color: $yellow;
    height: 100px;
    .logoImg {
      margin-bottom: 0.25rem;
    }
    .logoMail {
      font-size: 2rem;
    }
    .footerBtnUp {
      color: white;
    }

    .copyrights {
      color: rgb(129, 129, 129);
      font-size: 1rem;
    }
    .bottomhorizon {
      color: rgba(255, 255, 255, 0.9);
    }
    .sliderWrap {
      width: 80%;
      margin: auto;
      animation: openSlider 0.5s;
      .rc-slider-dot {
        width: 15px;
        height: 15px;
        bottom: -6px;
        margin-left: -10px;
        background-color: rgba(255, 255, 255, 0.8);
      }
      .rc-slider-rail {
        top: 6px;
        height: 2px;
      }
      .rc-slider-track {
        top: 6px;
        height: 2px;
        background-color: yellow;
      }
      .rc-slider-handle {
        margin-top: -5px;
        margin-left: -2px;
        background-color: yellow;
      }
      .rc-slider-mark-text-active {
        color: yellow;
      }
    }
    @keyframes openSlider {
      0% {
        transform: translateY(-100px);
      }
      100% {
        transform: translateY(0px);
      }
    }
  }
  .footerHide {
    opacity: 0.2;
  }
  .openhistory {
    width: 50px;
    height: 50px;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    font-size: 2rem;
    line-height: 50px;
    color: greenyellow;
    cursor: pointer;
  }
  .openhistory:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .closehistory {
    font-size: 2rem;
    color: white;
    cursor: pointer;
  }
}
.parent {
  position: relative;
  height: 100%;
}
.underWrap {
  user-select: none;
  pointer-events: none;
}
.underWrap {
  transform: translateY(0px);
  transition: all 1s;
}
.underWrapOff {
  transform: translateY(2000px);
  transition: all 2s;
}
.underContext {
  padding-top: 50px;
  padding-left: 50px;
  width: 100%;
  margin: auto;

  p {
    word-break: keep-all;
    clear: both;
    text-align: left;
    line-height: 25px;
    font-size: 1.25rem;
    font-weight: 100;
    color: rgb(255, 255, 255);
  }
  img {
    width: 500px;
    display: block;
    float: left;
    margin-bottom: 20px;
  }

  .projectsWrap {
    margin-top: 50px;

    .projectTitle {
      font-size: 1rem;
      font-weight: 500;
      color: rgb(255, 255, 255);
    }
    .projectDetail {
      color: rgb(255, 255, 255);
    }
  }
}
.historydescWrap {
  width: 100%;
  margin: auto;
  line-height: 5rem;
  position: absolute;
  bottom: 100px;
  animation: openHistoryContents 0.5s;

  .historyDesc {
    color: white;
    width: 80%;
    margin: auto;
    font-size: 1.25rem;
    padding: 10px 0;
    margin-bottom: 0;
    border-radius: 30px;

    background-color: rgba(0, 0, 0, 0.2);

    // bottom: 100px;
    // height: 200px;
  }
  @keyframes openHistoryContents {
    0% {
      transform: translateY(500px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  .historyDate {
    color: white;
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .copyrights {
    display: none;
  }

  .sliderWrap {
    width: 80%;
    margin: auto;
    animation: openSlider 0.5s;
    .rc-slider-dot {
      width: 25px !important;
      height: 25px !important;
      bottom: -6px;
      margin-left: -10px;
      background-color: rgba(255, 255, 255, 0.8);
    }
    .rc-slider-rail {
      top: 2px !important;
      height: 2px;
    }
    .rc-slider-track {
      top: 2px !important;
      height: 2px;
      background-color: yellow;
    }
    .rc-slider-handle {
      margin-top: -9px !important;
      margin-left: 2.5px !important;
      background-color: yellow;
    }
    .rc-slider-mark-text-active {
      color: yellow;
    }
    .rc-slider-mark-text {
      opacity: 0;
      border: 1px solid white;
      top: -50px;
      height: 80px;
      width: 18.6666%;
    }
  }

  .historydescWrap {
    position: absolute;
    bottom: 120px;
    width: 100%;
    .historyDesc {
      width: 90%;
      border-radius: 10px;
      font-size: 1.1rem;
      line-height: 2rem;
    }
  }

  .contentRichWrap {
    div {
      p {
        font-size: 1.5rem;
      }
    }
  }
  .footer {
    bottom: 30px !important;
  }
  .underContext {
    padding-top: 10px;
    padding-left: 10px;
    width: 100%;
    p {
      clear: both;
      text-align: left;
      line-height: 20px;
      font-size: 1.1rem;
      word-break: keep-all;
    }
    img {
      width: 90%;
      display: block;
      float: none;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .projectsWrap {
    margin-top: 10px !important;
    .projectTitle {
      font-size: 1rem !important;
    }
  }
  .hideMobile {
    display: none;
  }
}
